import { clone, find, get, isEmpty, mapValues, reverse } from "lodash"

const getConstructionYearMonthOptions = (isFrom) => {
  let arr = [
    { text: "1950年", value: "1950" },
    { text: "1960年", value: "1960" },
    { text: "1970年", value: "1970" },
    { text: "1980年", value: "1980" },
    { text: "1981年", value: "1981" },
    { text: "1982年", value: "1982" },
    { text: "1983年", value: "1983" },
    { text: "1990年", value: "1990" },
    { text: "1995年", value: "1995" },
    { text: "2000年", value: "2000" },
    { text: "2001年", value: "2001" },
    { text: "2005年", value: "2005" },
  ]
  const today = new Date()
  const thisYear = today.getFullYear()
  for (let i = 2010; i <= thisYear; i++) {
    arr.push({ text: `${i}年`, value: `${i}` })
  }
  if (isFrom) {
    arr = [{ text: "下限なし", value: "" }].concat(arr)
  } else {
    arr.push({ text: "上限なし", value: "" })
  }
  return arr
}

/**
 * @typedef {Object} FilterOption
 * @property {{ text: string, value: string}[]} option
 * @property {string} dotNotation
 * @property {string} label
 * @property {string} [defaultValue]
 */

/**
 * @type {{[name: string]: FilterOption}}
 */
export const data = {
  contractType: {
    options: [
      { text: "成約", value: "contract" },
      { text: "売出中", value: "sale" },
    ],
    dotNotation: "contractType",
    label: "事例種類",
  },
  contractDate: {
    options: [
      { text: "全て", value: "all" },
      { text: "1年以内", value: "1" },
      { text: "3年以内", value: "3" },
      { text: "5年以内", value: "5" },
      { text: "10年以内", value: "10" },
      { text: "すべて", value: "0" },
    ],
    defaultValue: "0",
    dotNotation: "contractDate",
    label: "契約年",
  },
  saleUpdateDate: {
    options: [
      { text: "全て", value: "all" },
      { text: "本日", value: "1" },
      { text: "7日以内", value: "7" },
      { text: "30日以内", value: "30" },
      { text: "90日以内", value: "90" },
      { text: "すべて", value: "0" },
    ],
    defaultValue: "0",
    dotNotation: "saleUpdateDate",
    label: "更新日",
  },
  rentUpdateDate: {
    options: [
      { text: "本日", value: "1" },
      { text: "3日以内", value: "3" },
      { text: "7日以内", value: "7" },
      { text: "14日以内", value: "14" },
      { text: "すべて", value: "0" },
    ],
    defaultValue: "0",
    dotNotation: "rentUpdateDate",
    label: "貸出日",
  },
  contractUnitPriceFrom: {
    options: [
      { text: "下限なし", value: "0" },
      { text: "30万", value: "30" },
      { text: "60万", value: "60" },
      { text: "90万", value: "90" },
      { text: "120万", value: "120" },
      { text: "150万", value: "150" },
      { text: "180万", value: "180" },
      { text: "210万", value: "210" },
      { text: "240万", value: "240" },
      { text: "270万", value: "270" },
      { text: "300万", value: "300" },
      { text: "330万", value: "330" },
      { text: "360万", value: "360" },
      { text: "390万", value: "390" },
      { text: "420万", value: "420" },
      { text: "450万", value: "450" },
      { text: "480万", value: "480" },
      { text: "510万", value: "510" },
      { text: "540万", value: "540" },
    ],
    defaultValue: "0",
    dotNotation: "contractUnitPrice.from",
    label: "契約㎡単価下限",
  },
  contractUnitPriceTo: {
    options: [
      { text: "30万", value: "30" },
      { text: "60万", value: "60" },
      { text: "90万", value: "90" },
      { text: "120万", value: "120" },
      { text: "150万", value: "150" },
      { text: "180万", value: "180" },
      { text: "210万", value: "210" },
      { text: "240万", value: "240" },
      { text: "270万", value: "270" },
      { text: "300万", value: "300" },
      { text: "330万", value: "330" },
      { text: "360万", value: "360" },
      { text: "390万", value: "390" },
      { text: "420万", value: "420" },
      { text: "450万", value: "450" },
      { text: "480万", value: "480" },
      { text: "510万", value: "510" },
      { text: "540万", value: "540" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "contractUnitPrice.to",
    label: "契約㎡単価上限",
  },
  contractPriceFrom: {
    options: [
      { text: "下限なし", value: "0" },
      { text: "500万", value: "500" },
      { text: "1000万", value: "1000" },
      { text: "1500万", value: "1500" },
      { text: "2000万", value: "2000" },
      { text: "2500万", value: "2500" },
      { text: "3000万", value: "3000" },
      { text: "3500万", value: "3500" },
      { text: "4000万", value: "4000" },
      { text: "4500万", value: "4500" },
      { text: "5000万", value: "5000" },
      { text: "5500万", value: "5500" },
      { text: "6000万", value: "6000" },
      { text: "6500万", value: "6500" },
      { text: "7000万", value: "7000" },
      { text: "7500万", value: "7500" },
      { text: "8000万", value: "8000" },
      { text: "8500万", value: "8500" },
      { text: "9000万", value: "9000" },
      { text: "9500万", value: "9500" },
      { text: "1億円", value: "10000" },
      { text: "2億円", value: "20000" },
      { text: "3億円", value: "30000" },
      { text: "4億円", value: "40000" },
    ],
    defaultValue: "0",
    dotNotation: "contractPrice.from",
    label: "契約金額下限",
  },
  contractPriceTo: {
    options: [
      { text: "500万", value: "500" },
      { text: "1000万", value: "1000" },
      { text: "1500万", value: "1500" },
      { text: "2000万", value: "2000" },
      { text: "2500万", value: "2500" },
      { text: "3000万", value: "3000" },
      { text: "3500万", value: "3500" },
      { text: "4000万", value: "4000" },
      { text: "4500万", value: "4500" },
      { text: "5000万", value: "5000" },
      { text: "5500万", value: "5500" },
      { text: "6000万", value: "6000" },
      { text: "6500万", value: "6500" },
      { text: "7000万", value: "7000" },
      { text: "7500万", value: "7500" },
      { text: "8000万", value: "8000" },
      { text: "8500万", value: "8500" },
      { text: "9000万", value: "9000" },
      { text: "9500万", value: "9500" },
      { text: "1億円", value: "10000" },
      { text: "2億円", value: "20000" },
      { text: "3億円", value: "30000" },
      { text: "4億円", value: "40000" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "contractPrice.to",
    label: "契約金額上限",
  },
  rentPriceFrom: {
    options: [
      { text: "下限なし", value: "0" },
      { text: "3万円", value: "3" },
      { text: "4万円", value: "4" },
      { text: "5万円", value: "5" },
      { text: "6万円", value: "6" },
      { text: "7万円", value: "7" },
      { text: "8万円", value: "8" },
      { text: "9万円", value: "9" },
      { text: "10万円", value: "10" },
      { text: "11万円", value: "11" },
      { text: "12万円", value: "12" },
      { text: "13万円", value: "13" },
      { text: "14万円", value: "14" },
      { text: "15万円", value: "15" },
      { text: "20万円", value: "20" },
      { text: "30万円", value: "30" },
      { text: "40万円", value: "40" },
      { text: "60万円", value: "60" },
      { text: "80万円", value: "80" },
      { text: "100万円", value: "100" },
      { text: "150万円", value: "150" },
    ],
    defaultValue: "0",
    dotNotation: "rentPrice.from",
    label: "賃料下限",
  },
  rentPriceTo: {
    options: [
      { text: "3万円", value: "3" },
      { text: "4万円", value: "4" },
      { text: "5万円", value: "5" },
      { text: "6万円", value: "6" },
      { text: "7万円", value: "7" },
      { text: "8万円", value: "8" },
      { text: "9万円", value: "9" },
      { text: "10万円", value: "10" },
      { text: "11万円", value: "11" },
      { text: "12万円", value: "12" },
      { text: "13万円", value: "13" },
      { text: "14万円", value: "14" },
      { text: "15万円", value: "15" },
      { text: "20万円", value: "20" },
      { text: "30万円", value: "30" },
      { text: "40万円", value: "40" },
      { text: "60万円", value: "60" },
      { text: "80万円", value: "80" },
      { text: "100万円", value: "100" },
      { text: "150万円", value: "150" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "rentPrice.to",
    label: "賃料上限",
  },
  exclusiveAreaFrom: {
    options: [
      { text: "下限なし", value: "0" },
      { text: "20㎡", value: "20" },
      { text: "30㎡", value: "30" },
      { text: "40㎡", value: "40" },
      { text: "50㎡", value: "50" },
      { text: "60㎡", value: "60" },
      { text: "70㎡", value: "70" },
      { text: "80㎡", value: "80" },
      { text: "90㎡", value: "90" },
      { text: "100㎡", value: "100" },
      { text: "110㎡", value: "110" },
      { text: "120㎡", value: "120" },
      { text: "130㎡", value: "130" },
      { text: "140㎡", value: "140" },
      { text: "150㎡", value: "150" },
      { text: "160㎡", value: "160" },
      { text: "170㎡", value: "170" },
      { text: "180㎡", value: "180" },
      { text: "190㎡", value: "190" },
      { text: "200㎡", value: "200" },
    ],
    defaultValue: "0",
    dotNotation: "exclusiveArea.from",
    label: "minAreaColumn",
  },
  exclusiveAreaTo: {
    options: [
      { text: "20㎡", value: "20" },
      { text: "30㎡", value: "30" },
      { text: "40㎡", value: "40" },
      { text: "50㎡", value: "50" },
      { text: "60㎡", value: "60" },
      { text: "70㎡", value: "70" },
      { text: "80㎡", value: "80" },
      { text: "90㎡", value: "90" },
      { text: "100㎡", value: "100" },
      { text: "110㎡", value: "110" },
      { text: "120㎡", value: "120" },
      { text: "130㎡", value: "130" },
      { text: "140㎡", value: "140" },
      { text: "150㎡", value: "150" },
      { text: "160㎡", value: "160" },
      { text: "170㎡", value: "170" },
      { text: "180㎡", value: "180" },
      { text: "190㎡", value: "190" },
      { text: "200㎡", value: "200" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "exclusiveArea.to",
    label: "maxAreaColumn",
  },
  landAreaFrom: {
    options: [
      { text: "下限なし", value: "0" },
      { text: "50㎡", value: "50" },
      { text: "60㎡", value: "60" },
      { text: "70㎡", value: "70" },
      { text: "80㎡", value: "80" },
      { text: "90㎡", value: "90" },
      { text: "100㎡", value: "100" },
      { text: "110㎡", value: "110" },
      { text: "120㎡", value: "120" },
      { text: "130㎡", value: "130" },
      { text: "140㎡", value: "140" },
      { text: "150㎡", value: "150" },
      { text: "200㎡", value: "200" },
      { text: "250㎡", value: "250" },
      { text: "300㎡", value: "300" },
      { text: "350㎡", value: "350" },
      { text: "400㎡", value: "400" },
      { text: "450㎡", value: "450" },
      { text: "500㎡", value: "500" },
    ],
    defaultValue: "0",
    dotNotation: "landArea.from",
    label: "土地面積の下限",
  },
  landAreaTo: {
    options: [
      { text: "50㎡", value: "50" },
      { text: "60㎡", value: "60" },
      { text: "70㎡", value: "70" },
      { text: "80㎡", value: "80" },
      { text: "90㎡", value: "90" },
      { text: "100㎡", value: "100" },
      { text: "110㎡", value: "110" },
      { text: "120㎡", value: "120" },
      { text: "130㎡", value: "130" },
      { text: "140㎡", value: "140" },
      { text: "150㎡", value: "150" },
      { text: "200㎡", value: "200" },
      { text: "250㎡", value: "250" },
      { text: "300㎡", value: "300" },
      { text: "350㎡", value: "350" },
      { text: "400㎡", value: "400" },
      { text: "450㎡", value: "450" },
      { text: "500㎡", value: "500" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "landArea.to",
    label: "土地面積の上限",
  },
  aboveGroundFloorLevelsFrom: {
    options: [
      { text: "0階", value: "0" },
      { text: "1階", value: "1" },
      { text: "2階", value: "2" },
      { text: "3階", value: "3" },
      { text: "4階", value: "4" },
      { text: "5階", value: "5" },
      { text: "6階", value: "6" },
      { text: "7階", value: "7" },
      { text: "8階", value: "8" },
      { text: "9階", value: "9" },
      { text: "10階", value: "10" },
      { text: "15階", value: "15" },
      { text: "20階", value: "20" },
      { text: "25階", value: "25" },
      { text: "30階", value: "30" },
      { text: "35階", value: "35" },
      { text: "40階", value: "40" },
      { text: "45階", value: "45" },
      { text: "50階", value: "50" },
      { text: "55階", value: "55" },
      { text: "60階", value: "60" },
    ],
    defaultValue: "0",
    dotNotation: "aboveGroundFloorLevels.from",
    label: "総階数下限",
  },
  aboveGroundFloorLevelsTo: {
    options: [
      { text: "1階", value: "1" },
      { text: "2階", value: "2" },
      { text: "3階", value: "3" },
      { text: "4階", value: "4" },
      { text: "5階", value: "5" },
      { text: "6階", value: "6" },
      { text: "7階", value: "7" },
      { text: "8階", value: "8" },
      { text: "9階", value: "9" },
      { text: "10階", value: "10" },
      { text: "15階", value: "15" },
      { text: "20階", value: "20" },
      { text: "25階", value: "25" },
      { text: "30階", value: "30" },
      { text: "35階", value: "35" },
      { text: "40階", value: "40" },
      { text: "45階", value: "45" },
      { text: "50階", value: "50" },
      { text: "55階", value: "55" },
      { text: "60階", value: "60" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "aboveGroundFloorLevels.to",
    label: "総階数上限",
  },
  floorFrom: {
    options: [
      { text: "上限なし", value: "0" },
      { text: "1階", value: "1" },
      { text: "2階", value: "2" },
      { text: "3階", value: "3" },
      { text: "4階", value: "4" },
      { text: "5階", value: "5" },
      { text: "6階", value: "6" },
      { text: "7階", value: "7" },
      { text: "8階", value: "8" },
      { text: "9階", value: "9" },
      { text: "10階", value: "10" },
      { text: "15階", value: "15" },
      { text: "20階", value: "20" },
      { text: "25階", value: "25" },
      { text: "30階", value: "30" },
      { text: "35階", value: "35" },
      { text: "40階", value: "40" },
      { text: "45階", value: "45" },
      { text: "50階", value: "50" },
      { text: "55階", value: "55" },
      { text: "60階", value: "60" },
    ],
    defaultValue: "0",
    dotNotation: "floor.from",
    label: "所在階下限",
  },
  floorTo: {
    options: [
      { text: "1階", value: "1" },
      { text: "2階", value: "2" },
      { text: "3階", value: "3" },
      { text: "4階", value: "4" },
      { text: "5階", value: "5" },
      { text: "6階", value: "6" },
      { text: "7階", value: "7" },
      { text: "8階", value: "8" },
      { text: "9階", value: "9" },
      { text: "10階", value: "10" },
      { text: "15階", value: "15" },
      { text: "20階", value: "20" },
      { text: "25階", value: "25" },
      { text: "30階", value: "30" },
      { text: "35階", value: "35" },
      { text: "40階", value: "40" },
      { text: "45階", value: "45" },
      { text: "50階", value: "50" },
      { text: "55階", value: "55" },
      { text: "60階", value: "60" },
      { text: "上限なし", value: "99999999999" },
    ],
    defaultValue: "99999999999",
    dotNotation: "floor.to",
    label: "所在階上限",
  },
  constructionYearmonthFrom: {
    options: getConstructionYearMonthOptions(true),
    dotNotation: "constructionYearmonth.from",
    defaultValue: "",
    label: "竣工年下限",
  },
  constructionYearmonthTo: {
    options: getConstructionYearMonthOptions(false),
    dotNotation: "constructionYearmonth.to",
    defaultValue: "",
    label: "竣工年上限",
  },
  floorPlanNumberOfRooms: {
    options: [
      { text: "1R/K/DK/LDK", value: "1" },
      { text: "2K/DK/LDK", value: "2" },
      { text: "3K/DK/LDK", value: "3" },
      { text: "4K/DK/LDK", value: "4" },
      { text: "5K以上", value: "5orMore" },
    ],
    dotNotation: "floorPlanNumberOfRooms",
    label: "部屋数",
  },
  ownerChange: {
    options: [
      { text: "全て", value: "all" },
      { text: "o/cのみ", value: "ownerChange" },
      { text: "o/cを除く", value: "notOwnerChange" },
    ],
    defaultValue: "all",
    dotNotation: "ownerChange",
    label: "オーナーチェンジ（o/c）",
  },
  filterPriceFrom: {
    options: [],
    defaultValue: "0",
    dotNotation: "filterPrice.from",
    label: "minPriceColumn",
  },
  filterPriceTo: {
    options: [],
    defaultValue: "99999999999",
    dotNotation: "filterPrice.to",
    label: "maxPriceColumn",
  },
}

const getDefaultOption = (key) => {
  const { defaultValue, options } = data[key]
  return find(options, (opt) => {
    return opt.value === defaultValue
  })
}

/**
 * 指定したフィルターのfromとtoを取得する。extraRangeの指定で範囲を広げるのも可能
 * @param {{key: string, value: string}} filterの種類とそれが取るvalue
 * @param {{extraRange?: string}} extraRangeを指定すると上下の範囲をその分だけ広げる
 * @returns
 */
export const getOptionsAround = ({ key, value }, option = {}) => {
  const extraRange = option.extraRange || 0
  const floatValue = parseFloat(value)
  const floatValueFrom = floatValue - extraRange
  const floatValueTo = floatValue + extraRange
  const keyFrom = `${key}From`
  const keyTo = `${key}To`
  const optionsFrom = get(data, `${keyFrom}.options`)
  const optionsTo = get(data, `${keyTo}.options`)
  if (isEmpty(optionsFrom) || isEmpty(optionsTo)) {
    return undefined
  }
  const optionsFromReversed = reverse(clone(optionsFrom))
  const from =
    find(optionsFromReversed, (opt) => {
      return parseInt(opt.value) <= floatValueFrom
    }) || getDefaultOption(keyFrom)
  const to =
    find(optionsTo, (opt) => {
      return floatValueTo <= parseInt(opt.value)
    }) || getDefaultOption(keyTo)
  return { from, to }
}

export const checkValidOptions = ({ key, value }) => {
  const keyFrom = `${key}From`
  const keyTo = `${key}To`
  const floatValueFrom = parseFloat(value.from)
  const floatValueTo = parseFloat(value.to)

  const optionsFrom = get(data, `${keyFrom}.options`)
  const optionsTo = get(data, `${keyTo}.options`)
  if (isEmpty(optionsFrom) || isEmpty(optionsTo) || isEmpty(value)) {
    return undefined
  }

  const from = find(optionsFrom, (opt) => {
    return parseInt(opt.value) === floatValueFrom
  })
  const to = find(optionsTo, (opt) => {
    return floatValueTo === parseInt(opt.value)
  })
  return !!from && !!to
}

export const options = mapValues(data, "options")
export const dotNotations = mapValues(data, "dotNotation")
export const labels = mapValues(data, "label")
export const defaultValue = mapValues(data, "defaultValue")
