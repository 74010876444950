const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

export function randomStr(N) {
  return Array.from(Array(N))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("")
}

export function randomTrue(chance) {
  return Math.random() < chance
}

export function generate256BitKey() {
  let key = ""
  for (let i = 0; i < 32; i++) {
    const randomByte = Math.floor(Math.random() * 256)
    key += randomByte.toString(16).padStart(2, "0")
  }
  return key
}
