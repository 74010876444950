import { usePropertyList } from "@/composables/propertyList/usePropertyList"
import { usePropertyHistory } from "@/composables/propertyList/usePropertyViewHistory"
import * as auth from "@/helpers/auth"
import { fetchApartmentImagesInCache } from "@/helpers/imagesCache"
import { Logger } from "@/helpers/logger"
import { setMetaTagOf } from "@/helpers/metaTag"
import { isCustomDomain } from "@/helpers/pageConstants"
import uaParser from "@/helpers/uaparser"
import { useAuthRoutes } from "@/router/authRoutes"
import store from "@/store.js"
import { conditionalComponent } from "@/views/conditionalComponent"
import { REGISTRATION_STEP } from "@share/const"
import { extractTextFromColon } from "@share/extractTextFromColon"
import { createRouter, createWebHistory } from "vue-router"
import {
  getClientEntityByUrlKey,
  getLocationFromUrlkeyClient,
  getPropertyViewHistory,
  postOwnerPageSeen,
  postPropertyDetailSeen,
} from "../helpers/api"
import beforeEachSettings from "./beforeEachSettings"
import { beforeConsultation, beforeFromMailToPage } from "./beforeFunctions"

const logger = Logger("router/index")
const topViewPromise = () => import("@/views/TopView.vue")

const beforeEnterList = async (to, from, next) => {
  setMetaTagOf.searchView()
  const propertyList = usePropertyList()

  if (!from.name && to.query.urlKey) {
    try {
      await store.dispatch("loadReference", to.query.urlKey)
      const locations = Object.values(
        await getLocationFromUrlkeyClient({ urlKey: to.query.urlKey })
      ).flat()
      propertyList.filter.locations = locations
      propertyList.filter.saleUpdateDate = "0"
      propertyList.filter.rentUpdateDate = "0"
      propertyList.filter.contractDate = "0"
    } catch (e) {
      console.log(e)
      return next({
        name: "search",
      })
    }
  }
  if (!propertyList.filter.locations.length && !to.query.filter) {
    return next({
      name: "search",
    })
  }
  return next()
}

const beforeEnterMapOrSpList = async (to, from, next) => {
  if (!isCustomDomain()) {
    return next({
      name: "not_found",
    })
  }
  // 物件詳細画面から戻ったときは何もしない
  beforeFromMailToPage(to)
  if (from.name === "property") {
    const { id, type, urlKey } = to.query
    let property = store.getters["properties/propertiesById"][id]
    if (!property) {
      property = Object.values(store.getters["properties/propertiesById"])[0]
    }
    if (store.state.properties.lastScale <= 0.05) {
      store.dispatch("properties/search", {
        location: property.location,
        position: property.position,
        propertyType: type,
        urlKey,
        deleteProperties: false,
        postActionLog: false,
      })
      store.dispatch("properties/selectProperty", null)
    }
    return next()
  }

  if (from.name === "building") {
    const { type, urlKey } = to.query
    if (store.state.properties.lastScale <= 0.05) {
      const property = store.getters["properties/properties"][0]
      store.dispatch("properties/search", {
        location: property.location,
        position: property.position,
        propertyType: type,
        urlKey,
        deleteProperties: false,
        postActionLog: false,
      })
      store.dispatch("properties/selectProperty", null)
    }
    return next()
  }

  // 直接アクセスしてきた場合
  if (!from.name) {
    // key によって物件を読み込む
    const toKey = to.query.key
    const fromKey = from.query.key
    // query.key が変化せず query.pin などが変化した場合は、物件を再読み込みしない
    const isInitialMapRenderingByKey = toKey && toKey !== fromKey
    if (isInitialMapRenderingByKey) {
      try {
        await store.dispatch("loadReference", toKey)
        store.dispatch("properties/loadProperties")
        store.dispatch("enableSessionFromUrlShare")
      } catch (e) {
        return next({
          name: "search",
        })
      }
    }

    const dataType = to.query.dataType || localStorage.getItem("dataType")

    if (["sale", "contract", "rent"].includes(dataType)) {
      store.dispatch(
        "updateDataType",
        ["sale", "contract", "rent"].includes(dataType) ? dataType : "sell"
      )
    }

    // name, id は optional
    const { name, latLng, type, id } = to.query
    if (latLng && type) {
      const [latString, lngString] = latLng.split("-")
      const position = {
        lat: parseFloat(latString),
        lng: parseFloat(lngString),
      }

      // 外部リンクからアクセスした場合、物件を読み込む
      if (from.matched.length === 0) {
        await store.dispatch("updateSateiInfo", {
          location: name || "",
          position,
          propertyType: type,
        })
        store.dispatch("retrieveFilter")
        if (to.name !== "map") {
          await store.dispatch("properties/loadProperties")
        }
      }

      if (id) {
        store.dispatch("properties/selectProperty", {
          id,
          latLng,
        })
        const property = store.getters["properties/selectedProperty"]
        if (property) {
          await store.dispatch("updateSateiInfoLocation", property.buildingName)
        }
      }

      const building = store.getters["properties/selectedBuilding"]
      if (building) {
        store.dispatch("updateFilterLocationName", building.propertyNames)
      }
    } else if (store.state.sateiTarget.position.lat) {
      store.dispatch("retrieveFilter")
      await store.dispatch("properties/loadProperties")
    }
  }

  const propertyList = usePropertyList()

  const { type, dataType } = to.query

  if (["sale", "contract", "rent"].includes(dataType)) {
    propertyList.filter.dataType = dataType
  }

  if (["apartment", "house"].includes(type)) {
    propertyList.filter.propertyType = type
    if (
      type === "apartment" &&
      (propertyList.filter.subPropertyType?.includes("onlyHouse") ||
        propertyList.filter.subPropertyType?.includes("onlyLand") ||
        !propertyList.filter.subPropertyType)
    ) {
      propertyList.filter.subPropertyType = ["apartment"]
    }
    if (
      (type === "house" &&
        propertyList.filter.subPropertyType?.includes("apartment")) ||
      !propertyList.filter.subPropertyType
    ) {
      propertyList.filter.subPropertyType = ["onlyHouse", "onlyLand"]
    }
  }

  next()
}

const beforeEnterPropertyOrQuestion = async (to, from, next) => {
  if (!isCustomDomain()) {
    return next({
      name: "not_found",
    })
  }
  const { propertyType, dataType: queryDataType, urlKey, path } = to.query
  beforeFromMailToPage(to)
  let latLng = to.query.latLng
  let id = to.query.id

  await store.dispatch("properties/deleteProperties")

  await store.dispatch("properties/loadPropertiesByPropertyIds", {
    [propertyType]: [to.query.propertyId],
  })
  const property = store.getters["properties/properties"].find(
    (x) => x.propertyId === to.query.propertyId
  )

  if (!property) {
    return next({ name: "search", query: { propertyExpired: true } })
  }

  await store.dispatch(
    "updateSateiInfoLocation",
    property.buildingName ||
      extractTextFromColon(
        property.locationName,
        property.propertyClassification
      )
  )
  if (urlKey) {
    const dataType = queryDataType || localStorage.getItem("dataType")

    if (["sale", "contract", "rent"].includes(dataType)) {
      store.dispatch(
        "updateDataType",
        ["sale", "contract", "rent"].includes(dataType) ? dataType : "sell"
      )
    }
    store.dispatch("updateDataType", dataType)
    try {
      await store.dispatch("loadReference", urlKey)
    } catch (e) {
      return next({
        name: "search",
      })
    }
  }

  store.dispatch("properties/saveUrlKey", { urlKey })

  if (property) {
    latLng = property.latLng
    id = property.id
  } else {
    return next({ name: "search", query: { propertyExpired: true } })
  }

  const dataType = queryDataType || localStorage.getItem("dataType")

  if (["sale", "contract", "rent"].includes(dataType)) {
    store.dispatch(
      "updateDataType",
      ["sale", "contract", "rent"].includes(dataType) ? dataType : "sell"
    )
  }
  await store.dispatch("properties/resetProperties", { property })
  store.dispatch("updateDataType", dataType)
  await store.dispatch("properties/selectProperty", { id, latLng })

  setMetaTagOf.detailPropertyView({ property })
  const propertyHistory = usePropertyHistory()

  if (store.getters.getGuestUrlKey) {
    postPropertyDetailSeen({
      propertyId: property.propertyId,
      location: property.location,
      propertyType: property.propertyType,
    }).then(async () => {
      const urlKey = store.getters.getGuestUrlKey
      const history = await getPropertyViewHistory({ urlKey })
      propertyHistory.setHistories(history)
    })
  }

  if (property.dataType !== "contract" && !store.getters.getGuestUrlKey) {
    propertyHistory.prependHistory({
      propertyId: property.propertyId,
      propertyType: property.propertyType,
    })
  }

  await fetchApartmentImagesInCache([property])
  store.dispatch("markImageCacheUpdated")

  next()
}

// TODO: conditionalComponent で SP PC で同じルートに
const routes = [
  // {
  //   path: "/",
  //   name: "top",
  //   beforeEnter(to, from, next) {
  //     if (to.query.code === "used") {
  //       alert("使用済みの認証コードです")
  //     }
  //     setMetaTagOf.lpView()
  //     next()
  //   },
  //   component: conditionalComponent({
  //     sp: () => import("@/views/LandingPageView.vue"),
  //     pc: wrapComponent(
  //       () => import("@/views/LandingPageView.vue"),
  //       () => import("@/components/auth/AuthLogin.vue")
  //     ),
  //   }),
  // },
  {
    path: "/",
    name: "top",
    beforeEnter(to, from, next) {
      if (to.query.code === "used") {
        alert("使用済みの認証コードです")
      }
      setMetaTagOf.lpView()
      next()
    },
    component: conditionalComponent({
      sp: () => import("@/views/NewSpLandingPageView.vue"),
      pc: () => import("@/views/NewLandingPageView.vue"),
    }),
    children: [
      {
        path: "",
        component: () =>
          import("management").then((module) => module.AuthLogin),
      },
    ],
  },
  // NOTE: estimateパスが入っていた昔のページのリダイレクト向け
  {
    path: "/estimate:path(.*)",
    name: "estimate",
    beforeEnter(to, from, next) {
      const matched = to.fullPath.match(/^\/estimate(?<path>\/.+)/)
      next(matched.groups.path)
    },
  },
  {
    path: "/pc",
    name: "pc_top",
    redirect: "/",
  },
  {
    path: "/sp",
    name: "sp_top",
    redirect: "/",
  },
  {
    path: "/owner",
    name: "owner",
    component: () => import("@/views/SpOwnerView.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      beforeFromMailToPage(to)
      const { urlKey, propertyId, propertyType } = to.query
      if (!urlKey) {
        next({ name: "sell_top" })
        return
      }
      if (propertyId && propertyType) {
        return next({
          name: "owner_property",
          query: to.query,
        })
      }
      if (from.name !== "owner_property") {
        postOwnerPageSeen({ urlKey })
      }

      const toKey = to.query.urlKey
      if (toKey) {
        try {
          await store.dispatch("loadReference", toKey)
          store.dispatch("enableSessionFromUrlShare")
        } catch (e) {
          return next()
        }
      }
      setMetaTagOf.ownerPageView()
      next()
    },
  },
  {
    path: "/owner/print",
    name: "owner_print",
    component: () => import("@/views/SpOwnerPrintView.vue"),
  },
  {
    path: "/owner/property",
    name: "owner_property",
    component: () => import("@/views/OwnerPropertyView.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      beforeFromMailToPage(to)
      const { urlKey, fromOwnerPage } = to.query
      if (!urlKey) {
        next({ name: "sell_top" })
        return
      }
      if (fromOwnerPage && !from.name) {
        const { fromOwnerPage, ...query } = to.query
        return next({
          name: "owner_property",
          query,
        })
      }
      const toKey = to.query.urlKey
      if (toKey) {
        try {
          await store.dispatch("loadReference", toKey)
          store.dispatch("enableSessionFromUrlShare")
        } catch (e) {
          return next()
        }
      }
      setMetaTagOf.ownerPageView()
      next()
    },
  },
  {
    path: "/owner/property/resistock",
    name: "owner_property_resistock",
    component: () => import("@/views/OwnerSearchPropertyResi.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      beforeFromMailToPage(to)
      const { urlKey, fromOwnerPage } = to.query
      if (!urlKey) {
        next({ name: "sell_top" })
        return
      }
      const toKey = to.query.urlKey
      if (toKey) {
        try {
          await store.dispatch("loadReference", toKey)
          store.dispatch("enableSessionFromUrlShare")
        } catch (e) {
          return next()
        }
      }
      setMetaTagOf.ownerPageView()
      next()
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/SearchView/SearchTopView.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.searchView()
      beforeFromMailToPage(to)
      // 直接アクセスしてきた場合
      if (!from.name) {
        // key によって物件を読み込む
        const toKey = to.query.key
        const fromKey = from.query.key
        // query.key が変化せず query.pin などが変化した場合は、物件を再読み込みしない
        const isInitialMapRenderingByKey = toKey && toKey !== fromKey
        if (isInitialMapRenderingByKey) {
          try {
            await store.dispatch("loadReference", toKey)
            store.dispatch("properties/loadProperties")
            store.dispatch("enableSessionFromUrlShare")
          } catch (e) {
            return next()
          }
        }
      }

      const propertyHistory = usePropertyHistory()
      if (store.getters.getGuestUrlKey) {
        const urlKey = store.getters.getGuestUrlKey
        const history = await getPropertyViewHistory({ urlKey })
        propertyHistory.setHistories(history)
      }

      next()
    },
  },
  {
    path: "/ai",
    name: "ai_top",
    beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.aiSateiView()
      return next({
        name: "sell_top",
        query: to.query,
      })
    },
  },
  {
    path: "/sell",
    name: "sell_top",
    children: [
      {
        path: "inheritance",
        name: "inheritance",
        component: () => import("@/views/LP/Inheritance.vue"),
      },
      {
        path: "divorce",
        name: "divorce",
        component: () => import("@/views/LP/Divorce.vue"),
      },
      {
        path: "loanhelp",
        name: "loanhelp",
        component: () => import("@/views/LP/LoanHelp.vue"),
      },
    ],
    component: () => import("@/views/SellTopView.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      beforeFromMailToPage(to)
      setMetaTagOf.aiSateiView()
      if (from.query.urlKey && !to.query.urlKey) {
        return next({
          ...to,
          query: { ...to.query, urlKey: from.query.urlKey },
        })
      }

      const toKey = to.query.urlKey
      if (toKey) {
        try {
          await store.dispatch("loadReference", toKey)
          store.dispatch("enableSessionFromUrlShare")
        } catch (e) {
          return next()
        }
      }
      next()
    },
  },
  {
    path: "/sell/inheritance",
    name: "inheritance",
    component: () => import("@/views/LP/Inheritance.vue"),
    beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.aiSateiView()
      next()
    },
  },
  {
    path: "/sell/divorce",
    name: "divorce",
    component: () => import("@/views/LP/Divorce.vue"),
    beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.aiSateiView()
      next()
    },
  },
  {
    path: "/sell/loanhelp",
    name: "loanhelp",
    component: () => import("@/views/LP/LoanHelp.vue"),
    beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.aiSateiView()
      next()
    },
  },
  {
    path: "/ai/form",
    name: "ai_form",
    component: () => import("@/views/AiSateiFormView.vue"),
    beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      setMetaTagOf.aiSateiView()
      next()
    },
  },
  {
    path: "/loan/form",
    name: "loan_form",
    component: () => import("@/views/LoanFormView.vue"),
    beforeEnter(to, from, next) {
      beforeFromMailToPage(to)
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      if (to.query["dealType"] === "buy") {
        setMetaTagOf.loanBuyView()
      }
      if (to.query["dealType"] === "sell") {
        setMetaTagOf.loanSellView()
      }
      next()
    },
  },
  {
    path: "/map",
    name: "map",
    component: conditionalComponent({
      sp: () => import("@/views/SpMapView.vue"),
      pc: () => import("@/views/PropertiesView.vue"),
    }),
    beforeEnter: beforeEnterMapOrSpList,
  },
  {
    // 建物詳細ページ
    // - 1 マップ画面から遷移してきた場合
    //   - つまり、建物情報がすでにある場合。すぐに遷移する。
    // - 2 外部でリンクを踏んで直接アクセスしてきた場合
    //   - SateiTarget を更新する
    //   - 最初は建物に含まれる物件だけを取得し、マップ画面に戻るときに他の物件を取得する
    path: "/building/:latLng",
    name: "building",
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      // 直接アクセスしてきた場合
      const { latLng } = to.params

      const [lat, lng] = latLng.split("-")

      const latNum = parseFloat(lat)
      const lngNum = parseFloat(lng)
      const isValidLatLng =
        -90 <= latNum && latNum <= 90 && -180 <= lngNum && lngNum <= 180
      if (!isValidLatLng) {
        // latLng が不正な値
        alert("緯度または経度が不正な値です。")
        return router.replace({ name: "search" })
      }

      const propertyType = to.query.type || to.query.propertyType || "apartment"

      const position = {
        lat: latNum,
        lng: lngNum,
      }

      store.dispatch(
        "updateDataType",
        ["sale", "contract", "rent"].includes(to.query.dataType)
          ? to.query.dataType
          : "sale"
      )

      store.dispatch("properties/loadPropertiesOfPosition", {
        position,
        propertyType,
        scale: 0.01,
        loadBuilding: true,
      })

      await store.state.process.promise
      store.dispatch("properties/selectProperty", { latLng })
      const building = store.getters["properties/selectedBuilding"]
      if (!building) {
        // その latLng に建物が存在しない
        alert("該当する建物が存在しません。")
        return router.replace({ name: "search" })
      }
      // OK
      store.dispatch("properties/selectProperty", building[0])
      if (!from.name) {
        await store.dispatch("updateSateiInfo", {
          location: building.buildingName,
          position,
          propertyType,
          deleteProperties: false,
        })
      } else if (from.name === "map") {
        const buildingByProperty = building.propertiesByType[to.query.dataType]
        if (buildingByProperty.length === 1) {
          return router.push({
            name: "property",
            query: {
              latLng,
              propertyType,
              propertyId: buildingByProperty[0]?.propertyId,
              dataType: buildingByProperty[0]?.dataType,
              id: buildingByProperty[0]?.id,
            },
          })
        }
      }

      const updatedAtLabel =
        store.getters["properties/latestBuildingUpdatedAtLabel"]
      setMetaTagOf.detailBuildingView({ building, updatedAtLabel })
      next()
    },
    component: conditionalComponent({
      sp: () => import("@/views/DetailBuildingView.vue"),
      pc: () => import("@/views/PropertiesView.vue"),
    }),
  },
  {
    // 物件詳細ページ
    path: "/property",
    name: "property",
    /**
     * TODO: リファクタ
     * latLng ベースの物件識別から propertyId ベースに移行したいが、様々なところを修正する必要がある。
     * 顧客履歴からのリンクに propertyId しか使えないので、一旦 latLng を取得するやり方で対応している。
     */
    beforeEnter: beforeEnterPropertyOrQuestion,
    component: () => import("@/views/PropertyDetailView.vue"),
  },
  ,
  {
    path: "/search/question",
    name: "question",
    children: [
      {
        path: "contact",
        name: "question-contact",
      },
      {
        path: "confirmed",
        name: "question-confirmed",
      },
    ],

    async beforeEnter(to, from, next) {
      return next({
        name: "property",
        query: to.query,
      })
    },
    component: conditionalComponent({
      sp: () => import("@/views/DetailPropertyView.vue"),
      pc: () => import("@/views/PropertiesView.vue"),
    }),
  },
  {
    path: "/confirm_verification",
    name: "confirm_verification",
    async beforeEnter(to, from, next) {
      if (!from.name) {
        const { type, verified } = to.query
        if (!["user"].includes(type)) {
          // 何もしない
        } else if (verified === "true") {
          window.alert("メールアドレスの認証が完了しました。")
        } else {
          window.alert(
            "メールアドレスの認証に失敗しました。運営にお問い合わせください。"
          )
        }
      }
      await auth.logout()
      next({ name: "pc_lp_login" })
    },
  },
  {
    path: "/pc/properties",
    name: "pc_properties",
    redirect: "/map",
  },
  {
    path: "/sp/map",
    name: "sp_map",
    redirect: "/map",
  },
  {
    path: "/pc/confirm",
    name: "pc_top_confirm",
    component: topViewPromise,
    beforeEnter(to, from, next) {
      if (!from.name) {
        return next({ name: "pc_lp_password_reset", query: to.query })
      }
    },
  },
  {
    path: "/pc/consultation",
    name: "pc_top_consultation",
    redirect: "/consultation",
  },
  {
    path: "/sp/consultation",
    name: "sp_top_consultation",
    redirect: "/consultation",
  },
  {
    path: "/consultation",
    name: "consultation",
    component: conditionalComponent({
      sp: () => import("@/views/SpSimpleMessageView.vue"),
      pc: () => import("@/views/TopView.vue"),
    }),
    beforeEnter: beforeConsultation,
    props: (route) => ({
      routeQuery: route.query,
      initialModalType: "CONSULTATION",
    }),
  },
  {
    path: "/sell_form",
    name: "sell_form",
    component: () => import("@/views/SellFormView.vue"),
    async beforeEnter(to, from, next) {
      if (!isCustomDomain()) {
        return next({
          name: "not_found",
        })
      }
      beforeFromMailToPage(to)
      setMetaTagOf.aiSateiView()
      if (from.query.urlKey && !to.query.urlKey) {
        return next({
          ...to,
          query: { ...to.query, urlKey: from.query.urlKey },
        })
      }

      const toKey = to.query.urlKey
      if (toKey) {
        try {
          await store.dispatch("loadReference", toKey)
          store.dispatch("enableSessionFromUrlShare")
        } catch (e) {
          return next()
        }
      }
      next()
    },
  },
  {
    // 面談用フォームのURL。2021/10/1現在は動線は存在しないが
    // 過去メールからはまだこのURLに着地可能なので最低限のリダイレクトだけはしておく
    path: "/pc/consultation/form",
    name: "pc_top_consultation_form",
    redirect: "/search",
  },
  {
    path: "/sp/consultation/form",
    name: "sp_top_consultation_form",
    redirect: "/search",
  },
  {
    path: "/management",
    component: conditionalComponent({
      sp: () => import("@/views/SpManagementView.vue"),
      pc: () => import("@/views/ManagementView.vue"),
    }),
    children: [
      // {
      //   path: "",
      //   name: "management",
      //   component: () => import("@/components/ListClientView.vue"),
      // },
      // {
      //   path: "",
      //   name: "management",
      //   alias: "/clients",
      //   component: () => import("@/components/Client/ListClient.vue"),
      // },
      // {
      //   path: "hankyo",
      //   name: "management_hankyo",
      //   component: () => import("@/components/ListFailedHankyo.vue"),
      //   beforeEnter(to, from, next) {
      //     // staff の場合は /management にリダイレクト
      //     if (!store.getters.isManager) {
      //       return next({ name: "management" })
      //     }

      //     return next()
      //   },
      // },
      // {
      //   path: "workflow",
      //   name: "workflow_list",
      //   component: () => import("@/views/ManagementView/WorkFlow.vue"),
      // },
      // {
      //   path: "settings/users",
      //   name: "management_users",
      //   component: () => import("@/views/ManagementView/ManageUsers.vue"),
      // },
      // {
      //   path: "settings/features",
      //   name: "management_features",
      //   component: () => import("@/views/ManagementView/ManageFeatures.vue"),
      // },
      {
        path: "admin/users",
        name: "corporate_users",
        component: () =>
          import("@/components/CorporateUser/ListCorporateUser.vue"),
        meta: { requiresRole: true },
        beforeEnter(to, from, next) {
          setMetaTagOf.managementView()
          next()
        },
      },
    ],
    async beforeEnter(to, from, next) {
      logger.debug("beforeEnter management")
      setMetaTagOf.managementView()
      if (store.getters.isLoggedIn) {
        logger.debug("user is logged in", {
          customerState: store.getters.customerState,
        })
        // if (store.getters.customerState === CUSTOMER_STATE_TYPE.EXPIRED) {
        //   /**
        //    * PN-1601 のワークアラウンド。本当はローカルストレージまわりとかのユーザーデータの持ち方を見直したい。
        //    * 強制的にリフレッシュしてそれでもだめなら mail me
        //    */
        //   await auth.refreshRegisterForm()
        //   if (store.getters.customerState === CUSTOMER_STATE_TYPE.EXPIRED) {
        //     return next({ name: "pc_lp_mail_me" })
        //   }
        // }
        const route = auth.checkRegistrationStep(REGISTRATION_STEP.COMPLETED)
        return next(route)
      } else {
        logger.debug("user is not logged in")
        return next({ name: "pc_lp_login" })
      }
    },
  },
  {
    path: "/pc/management",
    redirect: "/management",
  },
  {
    path: "/sp/management",
    redirect: "/management",
  },
  // {
  //   path: "/management/google_callback",
  //   name: "google_callback",
  //   component: () => import("@/views/ManagementView/GoogleCallback.vue"),
  // },
  {
    path: "/pc/corporation",
    redirect: "/management/admin/users",
    // name: "corporate_users",
    // component: () =>
    //   import(
    //      "@/views/CorporateUsersView.vue"
    //   ),
    // meta: { requiresRole: true },
    // beforeEnter(to, from, next) {
    //   setMetaTagOf.managementView()
    //   next()
    // },
  },
  {
    path: "/pc/unsubscribe",
    name: "pc_unsubscribe",
    redirect: "/unsubscribe",
  },
  {
    path: "/sp/unsubscribe",
    name: "sp_unsubscribe",
    redirect: "/unsubscribe",
  },
  {
    path: "/unsubscribe",
    name: "unsubscribe",
    beforeEnter: async (to, from, next) => {
      beforeFromMailToPage(to)
      const { urlKey, dealType } = to.query
      if (!urlKey || !dealType) {
        return next({ name: "search" })
      }
      try {
        const client = await getClientEntityByUrlKey({ urlKey })
        if (!client) {
          return next({ name: "search" })
        }
      } catch (e) {
        return next({ name: "search" })
      }
      next()
    },
    component: topViewPromise,
  },
  {
    path: "/sp/filter",
    name: "sp_filter",
    component: () => import("@/views/SpFilterView.vue"),
  },
  {
    path: "/sp/list",
    name: "sp_list",
    component: () => import("@/views/SpListView.vue"),
    beforeEnter: beforeEnterMapOrSpList,
  },
  {
    path: "/sp/confirm",
    name: "sp_top_confirm",
    component: topViewPromise,
    beforeEnter(to, from, next) {
      return next({ name: "pc_lp_password_reset", query: to.query })
    },
  },
  {
    path: "/pc/confirm_email_send_from",
    name: "pc_confirm_email_send_from",
    component: topViewPromise,
  },
  {
    path: "/sp/confirm_email_send_from",
    name: "sp_confirm_email_send_from",
    component: () => import("@/views/SpSimpleMessageView.vue"),
  },
  {
    path: "/sp/simple_message",
    name: "sp_simple_message",
    component: () => import("@/views/SpSimpleMessageView.vue"),
  },
  {
    path: "/sp/auth",
    name: "sp_auth",
    component: () => import("@/views/SpAuthView.vue"),
    props: (route) => ({
      msgType: route.query,
    }),
  },
  {
    path: "/not_supported",
    name: "not_supported",
    component: () => import("@/views/NotSupported.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not_found",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/:userId/line_callback",
    name: "line_callback",
    component: () => import("@/views/LineCallbackTopView.vue"),
  },
  {
    path: "/searchBar",
    name: "searchBar",
    component: () => import("@/views/SearchPage.vue"),
  },
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/PropertyListView.vue"),
    beforeEnter: beforeEnterList,
  },
]

useAuthRoutes(routes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  exactActiveClass: "router-link-exact-active",
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})
// Vue.use(Router)
// const router = new Router({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// })

router.beforeEach(async (to, from, next) => {
  const isPcPath = (route) => {
    return /^pc/.test(route.name)
  }
  const isSpPath = (route) => {
    return /^sp/.test(route.name)
  }
  const isMobile = uaParser.isMobile()
  const isIE11 = uaParser.isIE11()
  if (isIE11) {
    return next({ name: "not_supported" })
  }

  const setting = beforeEachSettings[to.name] || {}
  const { beforeFunction, spName, pcName } = setting

  if (
    to.matched.some((record) => record.meta.requiresRole) &&
    !store.getters.isAdminRole
  ) {
    return next({ name: "pc_top" })
  }

  if (isPcPath(to) && isMobile) {
    if (spName) {
      return next({
        name: spName,
        query: to.query,
      })
    }
    return next({
      name: "sp_top",
    })
  }

  if (isSpPath(to) && !isMobile) {
    if (pcName) {
      return next({
        name: pcName,
        query: to.query,
      })
    }
    return next({ name: "pc_top" })
  }

  if (beforeFunction) {
    await beforeFunction(to, from, next)
  }

  // NOTE: localhostでのdebug用のコード。必要に応じてコメントアウト外す
  // if (process.env.NODE_ENV === "localserver") {
  //   next();
  //   return;
  // }

  return next()
})

export default router
