import { isEmpty, omitBy } from "lodash"
import { TYPE_FORM_ID, TYPE_FORM_URL_BASE } from "./const"
import { Property } from "./entities/Property"

const getPathname = (url) => {
  const obj = new URL(url)
  const pathname = obj && obj.pathname
  return pathname || ""
}

export const getUnsubscribeUrl = ({ domain, urlKey, dealType, mailId }) => {
  return `${domain}/pc/unsubscribe?modal=Unsubscribe&urlKey=${urlKey}&dealType=${dealType}&mailId=${mailId}`
}

export const isUnsubscribeUrl = (url) => {
  return /unsubscribe/.test(getPathname(url))
}

export const isLocalhost = () => {
  return window.location.hostname === "pinrich.local"
}

export const isTypeformUrl = (url) => {
  const obj = new URL(url)
  const hostname = obj && obj.hostname
  return hostname === "pinrich.typeform.com"
}

export const isSellFormUrl = (url) => {
  return /sell_form/.test(getPathname(url))
}

export const isToPropertyDetailsUrl = (url) => {
  return /property/.test(getPathname(url))
}

export const isToSearchQuestionUrl = (url) => {
  return /question/.test(getPathname(url))
}

export const isAiSateiUrl = (url) => {
  const pathName = getPathname(url)
  return pathName === "/ai" || pathName === "/sell"
}

export const isLoanBuyUrl = (url) => {
  const pathName = getPathname(url)
  const obj = new URL(url)
  return (
    pathName.startsWith("/loan") && obj.searchParams.get("dealType") === "buy"
  )
}

export const isLoanSellUrl = (url) => {
  const pathName = getPathname(url)
  const obj = new URL(url)
  return (
    pathName.startsWith("/loan") && obj.searchParams.get("dealType") === "sell"
  )
}

export const isOwnerUrl = (url) => {
  return /owner/.test(getPathname(url))
}

export const isStockUrl = (url) => {
  return /stock/.test(getPathname(url))
}

// 判定の方法としては若干荒いけど、/estimate/map -> /map のような混在状態も存在するのでここらへんが落とし所
export const isSearchResultUrl = (url) => {
  return /map/.test(getPathname(url))
}

export const isSearchTopPageUrl = (url) => {
  return /search/.test(getPathname(url))
}

export const getAiSateiUrl = ({ domain }) => {
  return `${domain}/ai`
}

export const getLoanUrl = ({ dealType }) => {
  return process.env.NODE_ENV === "production"
    ? "https://pinrich.com/api/clients/moge?dealType=" + dealType
    : "https://develop.pinrich.com/api/clients/moge?dealType=" + dealType
}

export const getManagementUrl = ({ domain }) => {
  return `${domain}/management`
}

export const getClientMailSampleIframeUrl = ({ domain, mailType, userId }) => {
  return `${domain}/api/mail_sample/${userId}/${mailType}/content`
}

export const detailPropertyPageUrl = ({ domain, property, urlKey }) => {
  const queryObj = detailPropertyPageQuery({ property, urlKey })
  const queryString = new URLSearchParams(queryObj)
  return `${domain}/property?${queryString}`
}

export const detailPropertyQuestionUrl = ({ domain, property, urlKey }) => {
  const queryObj = detailPropertyPageQuery({ property, urlKey })
  const queryString = new URLSearchParams(queryObj)
  return `${domain}/search/question?${queryString}`
}

export const getSearchPageUrl = ({ domain }) => {
  return `${domain}/search`
}

export const getUserPageUrl = ({ domain, urlKey }) => {
  return `${domain}/list?urlKey=${urlKey}`
}

/**
 * Generates the URL for the owner page.
 *
 * @param {object} options - Options for generating the URL.
 * @param {string} options.domain - The domain of the URL.
 * @param {Property} [options.property] - The property object.
 * @param {string} options.urlKey - The URL key.
 * @return {string} The generated URL.
 */
export const getOwnerPageUrl = ({ domain, property = undefined, urlKey }) => {
  const queryString = new URLSearchParams(
    omitBy(
      {
        urlKey,
        propertyId: property?.propertyId,
        propertyType: property?.propertyType,
      },
      isEmpty
    )
  )
  return `${domain}/owner?${queryString}`
}

/**
 * @typedef {"login_fail" | "blacklist_token" | "no_token_found" | "verify_fail" } FailureMessage
 */

/**
 * @param {FailureMessage} message
 * @returns {`/api/auth/unauthorized/${string}`}
 */
export const apiUnAuthorizedUrl = (message) => {
  return `/api/auth/unauthorized/${message}`
}

export const detailPropertyPageQuery = ({ property, urlKey }) => {
  const result = {
    latLng: property.latLng,
    propertyType: property.propertyType,
    propertyId: property.propertyId,
    dataType: property.dataType,
    id: property.id,
  }
  if (urlKey) {
    result.urlKey = urlKey
  }
  return result
}

const parseUrlLikeString = (str) => {
  // http://xxx or https://xxx
  if (/^http/.test(str)) {
    return new URL(str)
  }
  // pinrich.local:8888
  if (/:/.test(str)) {
    const [hostname, port] = str.split(":")
    return {
      protocol: "http",
      host: str,
      hostname,
      port,
    }
  }
  // direct ip address
  return {
    hostname: str,
    host: str,
  }
}

export const getFQDNFromDefaultAndSubdomain = (defaultDomain, subdomain) => {
  const obj = parseUrlLikeString(defaultDomain)
  obj.host = `${subdomain}.${obj.host}`
  return urlFromObject(obj)
}

const urlFromObject = (urlObject) => {
  return String(Object.assign(new URL("http://a.com"), urlObject)).slice(0, -1)
}

/**
 * @param fullUrl 対象のURL
 * @returns {string} プロトコル＋FQDN+ポート
 * @example
 * // return "https://pinrich.com:5000"
 * getDomain("https://pinrich.com:5000/some/path")
 */
export const getDomain = (fullUrl) => {
  const parsed = parseUrlLikeString(fullUrl)
  const { protocol, hostname, port } = parsed
  let result = `${protocol}//${hostname}`
  if (port) {
    result += `:${port}`
  }
  return result
}

/**
 * @description fullUrlからFQDNだけを抜き出す
 * @param fullUrl 対象のURL
 * @returns {string} FQDN
 * @example
 * // return pinrich.com
 * getFQDN("https://pinrich.com:5000/some/path")
 */
export const getFQDN = (fullUrl) => {
  const parsed = parseUrlLikeString(fullUrl)
  const { hostname } = parsed
  return hostname
}

// TODO: mailKey -> mailId
export const getTypeFormInputUrl = ({
  env,
  typeFormKey,
  urlKey,
  mailKey = "",
}) => {
  return `${TYPE_FORM_URL_BASE}/${TYPE_FORM_ID[typeFormKey]}?url_key=${urlKey}&env=${env}&mail_key=${mailKey}`
}

export const getSellFormInputUrl = ({
  domain,
  urlKey,
  mailKey,
  mailType = "",
}) => {
  return `${domain}/sell_form?urlKey=${urlKey}&mailId=${mailKey}&mailType=${mailType}`
}

export const getLineWebhookUrl = ({ domain, userId }) => {
  return `${domain}/api/webhook/line_bot/${userId}`
}

export const getLineCallbackUrl = ({ domain, userId }) => {
  return `${domain}/${userId}/line_callback`
}

export const getUnsubscribeUrlOneClick = ({
  domain,
  urlKey,
  dealType,
  mailType,
}) => {
  return `${domain}/api/user/unsubscribe_one_click?urlKey=${urlKey}&dealType=${dealType}&mailType=${mailType}`
}

export const getLandingPageUrl = ({ domain, urlKey, reason }) => {
  switch (reason) {
    case "相続":
      return `${domain}/sell/inheritance`
    case "離婚":
      return `${domain}/sell/divorce`
    case "金銭問題":
      return `${domain}/sell/loanhelp`
    default:
      return ``
  }
}
