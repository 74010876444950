import axios from "@/helpers/axios"
import { Logger } from "@/helpers/logger"
import { User } from "@share/entities/User"

const logger = Logger("apiAuth")

/**
 * @typedef {
 *   email: string,
 *   password: string,
 *   corporateName: string,
 *   userName: string,
 *   phoneNumber: string,
 *   userIcon: string,
 *   iconName: string,
 *   subdomain: string,
 * } UserData
 */

/**
 *
 * @param email
 * @param password
 * @returns {Promise<{token: string, user: User}>}
 */
export const login = async (email, password) => {
  const response = await axios.post("/auth/login", {
    username: email,
    password,
  })
  const { data } = response
  const { token, user } = data
  return {
    token,
    user: new User(user),
  }
}

/**
 *
 * @param {UserData} userData
 * @returns {Promise<undefined>}
 */
export const postUserAdmin = async (userData) => {
  await axios.post(`/admin/users`, {
    ...userData,
  })
  return undefined
}

export const logout = async () => {
  await axios.post(`/auth/logout`)
  return undefined
}

export const changePassword = async (email, newPassword) => {
  await axios.post(`/auth/change_password`, {
    email,
    newPassword,
  })
  return undefined
}

export const staffResetPassword = async (email, newPassword, confirmToken) => {
  await axios.post(`/auth/staff_reset_password`, {
    email,
    newPassword,
    confirmToken,
  })
  return undefined
}

export const forgotPassword = async (email) => {
  await axios.post(`/auth/forgot_password`, {
    email,
  })
  return undefined
}

export const confirmForgotPassword = async (email, password, confirmCode) => {
  await axios.post(`/auth/confirm_forgot_password`, {
    email,
    password,
    confirmCode,
  })
}

// export const updateCustomerDataOnStripe = async (
//   userId,
//   { email, corporateName, userName, phone, photoUrl, photoName }
// ) => {
//   const response = await axios.put(`/users/${userId}/customer`, {
//     email,
//     corporateName,
//     userName,
//     phone,
//     photoUrl,
//     photoName,
//   })
//   const { data } = response
//   return new User(data)
// }

export const updateUserProfile = async (
  userId,
  { email, corporateName, userName, phone, photoUrl, photoName }
) => {
  logger.debug("updateUserProfileInput", {
    email,
    corporateName,
    userName,
    phone,
    photoUrl,
    photoName,
  })
  const response = await axios.put(`/users/${userId}/profile`, {
    email,
    corporateName,
    userName,
    phone,
    // FIXME: ここで名前の捻れを吸収しているが
    //  photoUrlとphotoNameの方をcognitoの属性値に合わせて根本的に解消したい
    userIcon: photoUrl,
    iconName: photoName,
  })
  const { data } = response
  return data
}
