import Flex from "@/components/baseLayout/Flex.vue"
import Stack from "@/components/baseLayout/Stack.vue"
import TemplateButton from "@/components/buttons/TemplateButton.vue"
import { BaseForm, FormInput } from "@/components/form"
import { BaseModal, BaseModalContainer } from "@/components/modals/BaseModal"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faArrowAltCircleDown,
  faContactBook,
} from "@fortawesome/free-regular-svg-icons"
import {
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCommentDots,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faGear,
  faHouse,
  faImage,
  faList,
  faSearch,
  faStreetView,
  faThumbtack,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { createGtm } from "@gtm-support/vue-gtm"
import { createPinia } from "pinia"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router/index"
import store from "./store"

import "destyle.css"
import "swiper/css/bundle"
import "vue-loading-overlay/dist/vue-loading.css"
import { usePropertyList } from "./composables/propertyList/usePropertyList"
import "./sass/_custom.scss"
import "./sass/_figma.scss"
import "./sass/_variables.scss"
import "./sass/partial/BaseInput.scss"
import "./style.css"

const pinia = createPinia()

const app = createApp(App)

app.use(store)
app.use(router)
app.use(pinia)

const propertyList = usePropertyList()

store.watch(
  (state, getters) => propertyList.filter,
  () => {
    for (const [key, value] of Object.entries(propertyList.filter)) {
      if (key in store.state.filter) {
        store.dispatch("updateFilterValue", { key, value })
      }
      if (key === "dataType") {
        store.dispatch("updateDataType", value)
      }
      if (key === "propertyType") {
        store.state.sateiTarget.propertyType = value
      }
      if (key === "subPropertyType") {
        store.dispatch("updateFilterValue", { key: "subPropertyType", value })
      }
    }
    if (router.currentRoute.value.name === "map") {
      store.dispatch("properties/loadPropertiesOnFilterChange", {
        propertyType: store.state.sateiTarget.propertyType,
      })
    }
  }
)

/* fontawesome */

// アイコンの追加
// https://fontawesome.com/docs/web/use-with/vue/add-icons

// import { faSearch as faSearchBrands } from "@fortawesome/free-brands-svg-icons"

library.add(
  // fa-solid
  faCommentDots,
  faEdit,
  faThumbtack,
  faSearch,
  faChevronCircleRight,
  faList,
  faImage,
  faExternalLinkAlt,
  faGear,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faHouse,
  faTrashCan,
  faExclamationCircle,
  faStreetView,
  // fa-regular
  faContactBook,
  faArrowAltCircleDown
)

// FaIcon コンポーネントの仕様
// https://fontawesome.com/docs/web/use-with/vue/style
app.component("FaIcon", FontAwesomeIcon)

const gtm = createGtm({
  id: import.meta.env.VITE_GTM_ID!,
  queryParams: {
    gtm_auth: import.meta.env.VITE_GTM_AUTH!,
    gtm_preview: import.meta.env.VITE_GTM_PREVIEW!,
    gtm_cookies_win: "x",
  },
  defer: true,
  enabled: true,
  debug: true,
})
app.use(gtm)

app.component("flex-l", Flex)
app.component("stack-l", Stack)
app.component("BaseModal", BaseModal)
app.component("BaseModalContainer", BaseModalContainer)
app.component("TemplateButton", TemplateButton)
app.component("BaseForm", BaseForm)
app.component("FormInput", FormInput)

app.mount("#app")
