import store from "@/store"
import { pageConstants } from "./pageConstants"

/**
 * @typedef {"map" | "property"} RouteNames
 * @typedef {import('vue-router').Route & { name?: RouteNames }} Route
 */

/**
 * route の変更を watch して meta を書き換える
 * query が変わっただけの場合でも発火させたいので、 navigation guard ではなく
 * App.vue コンポーネントの watch で監視する
 * @param {Route} to
 * @param {Route} from
 */
export const watchRoute = (to, from) => {
  handlers[to.name]?.(to, from)
}

/**
 * to.name に対応して setMetaTag を呼ぶ関数を定義する
 * @type {{ [key in RouteNames]: {(to: Route, from: Route): void }}
 */
const handlers = {
  async map(to) {
    setMetaTagOf.mapView({
      propertyTypeLabel: store.getters.propertyTypeLabel,
      location: to.query.name || (await getFallbackLocation()),
    })

    async function getFallbackLocation() {
      // これが呼ばれる段階では properties などがロード中なので、更新されるのを待つ
      await store.state.process.promise

      const locationName =
        store.getters["properties/selectedProperty"]?.locationName1 ||
        store.getters["properties/properties"][0]?.locationName1

      return locationName
    }
  },
}

// setMetaTag の画面ごとのラッパー
export const setMetaTagOf = {
  // LP
  lpView() {
    setMetaTag(
      createPinrichMeta({
        googlebot: "all",
      })
    )
  },

  // 検索画面
  searchView() {
    setMetaTag(createUserMeta())
  },

  // マップ画面
  mapView({ propertyTypeLabel, location }) {
    if (!propertyTypeLabel) {
      return
    }
    const title = location
      ? `${propertyTypeLabel} | ${location}`
      : propertyTypeLabel
    const description =
      (location ? `${location}周辺の` : "") +
      `${propertyTypeLabel}の最新情報を毎日更新しています。`
    setMetaTag(
      createUserMeta({
        title,
        description,
        "og:type": "article",
        googlebot: "noindex,nofollow",
        canonical: false,
      })
    )
  },

  // 建物詳細
  detailBuildingView({
    building,
    updatedAtLabel, // 最新の物件のupdatedAt xxxx年xx月xx日
  }) {
    const {
      buildingName,
      address,
      propertyTypeLabel,
      saleProperties,
      contractProperties,
    } = building
    const title =
      (buildingName ? `${buildingName} | ` : "") +
      `${address}周辺の${propertyTypeLabel}`
    let description = `${buildingName}（${address}）の募集中の売出${
      saleProperties.length || 0
    }件と成約${contractProperties.length || 0}件を掲載しています。`
    if (updatedAtLabel) {
      description += `最新情報は、${updatedAtLabel}に更新されました。`
    }
    setMetaTag(
      createUserMeta({
        title,
        description,
        googlebot: "all",
      })
    )
  },

  // 部屋詳細
  detailPropertyView({ property }) {
    const { locationName, contractPrice } = property
    const title = `${contractPrice}万円 | ${locationName}`
    setMetaTag(
      createUserMeta({
        title,
        // description,
        googlebot: "all",
      })
    )
  },

  // 配信設定
  urlShareEditView() {
    setMetaTag(
      createUserMeta({
        title: "配信設定を変更",
      })
    )
  },

  managementView() {
    setMetaTag(createPinrichMeta())
  },

  aiSateiView() {
    setMetaTag(
      createUserMeta({
        title: aiSateiMetaTitle || metaTitle,
        description: aiSateiMetaDescription || metaDescription,
      })
    )
  },

  loanBuyView() {
    setMetaTag(
      createUserMeta({
        title: loanBuyMetaTitle || metaTitle,
        description: loanBuyMetaDescription || metaDescription,
      })
    )
  },

  loanSellView() {
    setMetaTag(
      createUserMeta({
        title: loanSellMetaTitle || metaTitle,
        description: loanSellMetaDescription || metaDescription,
      })
    )
  },

  sellForm() {
    setMetaTag(
      createUserMeta({
        title: "物件提案のご要望",
        description:
          "希望エリア、実現したいライフスタイル、購入条件を詳しく教えてください。ご要望に沿う物件が出ましたら専門家からタイムリーにご提案させていただきます。",
      })
    )
  },

  ownerPageView() {
    setMetaTag(
      createUserMeta({
        title: "オンライン査定",
        description:
          "簡易的なオンライン査定書を閲覧でき、最新データから価格更新が可能です。定期的な不動産価格の見直しにご活用ください。",
      })
    )
  },
}

const {
  subdomain,
  originalMetaTitle,
  originalMetaDescription,
  metaTitle,
  metaDescription,
  metaImageSrc,
  aiSateiMetaTitle,
  aiSateiMetaDescription,
  loanBuyMetaTitle,
  loanBuyMetaDescription,
  loanSellMetaTitle,
  loanSellMetaDescription,
} = pageConstants.siteSettings
const baseUserMetaSettings = {
  title: originalMetaTitle,
  metaTitle: originalMetaTitle,
  description: originalMetaDescription,
  metaDescription: originalMetaDescription,
  subdomain,
  baseUrl: import.meta.env.VITE_BASE_URL_FULL,
  path: "",
  imageUrl: metaImageSrc,
}
const basePinrichMetaSettings = {
  title: "PinRich",
  description:
    "ピンリッチは、不動産仲介会社へ取引の機会を提供し、エンドユーザーには住宅情報を供給するプラットフォームです。ピンリッチのサービスは、理想の物件を探す買主や借主、自宅の価値が明確でない売主に、必要なデータをわかりやすく提供することにより、不動産仲介会社の役割を補完します。",
  baseUrl: import.meta.env.VITE_BASE_URL_FULL,
  path: "",
  imagePath: "assets/pinrich-banner_OGP.png",
}

const createUserMeta = (params = {}) => {
  return createMeta({ ...baseUserMetaSettings, ...params })
}
const createPinrichMeta = (params = {}) => {
  return createMeta({ ...basePinrichMetaSettings, ...params })
}

let _baseUrl = undefined
const createMeta = ({
  title,
  description,
  subdomain,
  baseUrl,
  path,
  imagePath,
  imageUrl,
  ...rest
}) => {
  if (!_baseUrl) {
    if (subdomain) {
      _baseUrl = baseUrl.split("//").splice(1, 0, `${subdomain}.`).join("")
    } else {
      _baseUrl = baseUrl
    }
  }
  const result = {
    title,
    description,
    keywords:
      "物件,住宅,不動産,売却,購入,価格,査定,相場,首都圏,PinRich,ピンリッチ",
    "og:type": "website",
    "og:title": title,
    "og:description": description,
    "og:url": _baseUrl + path,
    "og:image": imageUrl || _baseUrl + imagePath,
    "twitter:card": "summary",
    "twitter:title": title,
    "twitter:description": description,
    "twitter:image": imageUrl || _baseUrl + imagePath,
    "fb:app_id": "1650300698440504",
    canonical: _baseUrl,
    googlebot: "nosnippet",
    ...rest,
  }

  return result
}

function setMetaTag(meta = {}) {
  // タイトルを設定
  if (meta.title) {
    document.title = meta.title
  }

  // description 設定
  if (meta.description) {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", meta.description)
  }

  // keywords 設定
  if (meta.keywords) {
    document
      .querySelector("meta[name='keywords']")
      .setAttribute("content", meta.keywords)
  }

  // "og:type" 設定
  if (meta["og:type"]) {
    document
      .querySelector("meta[property='og:type']")
      .setAttribute("content", meta["og:type"])
  }

  // "og:title" 設定
  if (meta["og:title"]) {
    document
      .querySelector("meta[property='og:title']")
      .setAttribute("content", meta["og:title"])
  }

  // "og:description" 設定
  if (meta["og:description"]) {
    document
      .querySelector("meta[property='og:description']")
      .setAttribute("content", meta["og:description"])
  }

  // "og:site_name" 設定
  if (meta["og:site_name"]) {
    document
      .querySelector("meta[property='og:site_name']")
      .setAttribute("content", meta["og:site_name"])
  }

  // "og:url" 設定
  if (meta["og:url"]) {
    document
      .querySelector("meta[property='og:url']")
      .setAttribute("content", meta["og:url"])
  }

  // "og:image" 設定
  if (meta["og:image"]) {
    document
      .querySelector("meta[property='og:image']")
      .setAttribute("content", meta["og:image"])
  }

  // "twitter:card" 設定
  if (meta["twitter:card"]) {
    document
      .querySelector("meta[name='twitter:card']")
      .setAttribute("content", meta["twitter:card"])
  }

  // "twitter:title" 設定
  if (meta["twitter:title"]) {
    document
      .querySelector("meta[name='twitter:title']")
      .setAttribute("content", meta["twitter:title"])
  }

  // "twitter:description" 設定
  if (meta["twitter:description"]) {
    document
      .querySelector("meta[name='twitter:description']")
      .setAttribute("content", meta["twitter:description"])
  }

  // "twitter:image" 設定
  if (meta["twitter:image"]) {
    document
      .querySelector("meta[name='twitter:image']")
      .setAttribute("content", meta["twitter:image"])
  }

  // "fb:app_id" 設定
  if (meta["fb:app_id"]) {
    document
      .querySelector("meta[property='fb:app_id']")
      .setAttribute("content", meta["fb:app_id"])
  }

  // googlebot設定
  if (meta.googlebot) {
    if (!document.querySelector("meta[name='googlebot']")) {
      const elm = document.createElement("meta")
      elm.setAttribute("name", "googlebot")
      document.getElementsByTagName("head")[0].appendChild(elm)
    }
    document
      .querySelector("meta[name='googlebot']")
      .setAttribute("content", meta.googlebot)
  } else {
    if (document.querySelector("meta[name='googlebot']")) {
      document.querySelector("meta[name='googlebot']").remove()
    }
  }

  // URL正規化設定
  if (meta.canonical === false) {
    if (document.querySelector("link[rel='canonical']")) {
      document.querySelector("link[rel='canonical']").remove()
    }
  } else {
    if (!document.querySelector("link[rel='canonical']")) {
      var link = document.createElement("link")
      link.setAttribute("rel", "canonical")
      document.getElementsByTagName("head")[0].appendChild(link)
    }
    document
      .querySelector("link[rel='canonical']")
      .setAttribute("href", meta.canonical)
  }
}
